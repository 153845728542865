@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&display=swap');


// Colors
$primary:       #000;
$secondary:     #CF0000;
$light:         #F4F4F4;
$danger:        #EB0000;
$dark:          #474B64;
$white:         #FFFFFF;
$gray:          #969696;
$light-gray:    #F8F8FA;

$success:       #91CC54;
$info:          #009CDE;
$warning:       #FFCF01;


$body-color: $dark;
$font-size-base:              0.875rem; // Assumes the browser default, typically `16px`
$font-size-sm:                $font-size-base * 0.75;
$small-font-size:             $font-size-base * 0.75;
$font-size-xs:                $font-size-base *0.625;
$font-size-lg:                $font-size-base * 1.252;
$enable-rfs: true;
$enable-negative-margins: true;



$font-family-base:'Inter', sans-serif;
$headings-font-family: 'Exo 2', sans-serif;
$headings-color:     $primary;
$h1-font-size: 2.5rem;//40
$h2-font-size: 2rem;//32
$h3-font-size: 1.755rem;//28
$h4-font-size: 1.5rem;//24
$h5-font-size: 1.25rem;//20
$h6-font-size: 1rem;//16
$h7-font-size: 0.875rem;//14
$headings-margin-bottom: 0;
$headings-font-weight: 600;
$font-weight-bold: 600;
$font-weight-bolder: 900;

// scss-docs-start type-variables
$lead-font-size:              1.25rem;
$lead-font-weight:            normal;

$paragraph-margin-bottom:0;
//Headings 
$display-font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
);
$display-font-weight: 800;


// reboot
$hr-margin-y: 0;
$hr-border-color: rgba($light-gray, 1);


// scss-docs-start theme-bg-subtle-variables
// $primary-bg-subtle:       tint-color($primary, 80%) !default;
// $secondary-bg-subtle:     tint-color($secondary, 80%) !default;
// $success-bg-subtle:       tint-color($success, 80%) !default;
// $info-bg-subtle:          tint-color($info, 80%) !default;
// $warning-bg-subtle:       tint-color($warning, 80%) !default;
// $danger-bg-subtle:        tint-color($danger, 80%) !default;
$light-bg-subtle:         #f5f5f5;
// $dark-bg-subtle:          $gray-400 !default;



// btns
$btn-font-family:             'Exo 2', sans-serif;
$btn-padding-y:                   10px;
$btn-padding-x:                   40px;
$btn-font-size:                   0.75rem;
$btn-line-height:                   2;
$btn-font-weight:                   500;
$btn-border-radius:                 0;

$badge-font-size:                   .5rem;
$badge-font-weight:                 800;
$badge-padding-y:                   .5rem;
$badge-padding-x:                   .75rem;
$badge-border-radius:               0;


//Form 
$input-placeholder-color: $light;


$link-decoration: none;

$spacers: (
  0: 0,
  1: 1px,
  2: 0.125rem,//2px
  4: 0.25rem,//4px
  6: 0.375rem,//6px
  8: 0.5rem	,//8px
  10: 0.625rem,//10px
  12: 0.75rem,//12px
  14: 0.875rem,//14px
  16: 1rem,//16px
  20: 1.25rem,//20px
  24: 1.5rem,//24px
  28: 1.75rem,//28px
  32: 2rem,//32px
  36: 2.25rem,//36px
  40: 2.5rem,//40px
  42: 2.63rem,
  44: 2.75rem,
  48: 3rem,
  56: 3.5rem,
  64: 4rem,
  70: 4.37rem,
  74: 4.625rem,
  90: 5.625rem,
  98: 6.12rem,
  112: 7rem,
  128: 8rem,
  130: 8.625rem,
  144: 9rem,
  150: 9.55rem,
  160: 10rem,
  171: 10.725rem,
  176: 11rem,
  180: 11.55rem,
  192: 12rem,
  208: 13rem,
  224: 14rem,
  240: 15rem,
  256: 16rem,
  288: 18rem,
  320: 20rem,
  384: 24rem 
);

//Nav 
$nav-link-color:                   $white;
$nav-link-hover-color:             $secondary;
$nav-link-font-weight:              600;
$navbar-dark-color:                 $white;
$navbar-dark-hover-color:           rgba($white, .55);
$navbar-dark-active-color:          rgba($white, .55);
// $navbar-padding-x: 2.5rem;
// $nav-link-padding-x:                2.5rem;
// $navbar-nav-link-padding-x:         2.5rem;
// $nav-link-font-weight:              700;
// $navbar-dark-color:                 $white;
// // $navbar-dark-brand-color:        #fff;
// // $navbar-dark-active-color:       #fff;
// $dropdown-spacer:                   0;
// $navbar-nav-link-padding-y:         0.9375rem;
// $nav-link-font-size:                $font-size-sm;
// $dropdown-font-size:                $font-size-sm;
// $navbar-brand-font-size:            $font-size-sm;
// $dropdown-color:                    $white;
// $dropdown-bg:                       $primary;
// $dropdown-link-color:               $white;
// $dropdown-link-hover-bg:            $secondary;
// $dropdown-link-active-color:        $secondary;
// $navbar-toggler-padding-y:          0;
// $navbar-toggler-padding-x:          0;
// $dropdown-border-width:             0;
// $dropdown-border-radius:            0;


//accordion
$accordion-padding-x: 0;
$accordion-button-active-bg:  transparent;
$accordion-button-active-color: $secondary;
$accordion-icon-color: $white;

// $accordion-button-icon:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='#{$accordion-icon-color}' stroke-linecap='round' stroke-linejoin='round'><path d='M2 5L8 11L14 5'/></svg>") !default;
// $accordion-button-active-icon:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='#{$accordion-icon-active-color}' stroke-linecap='round' stroke-linejoin='round'><path d='M2 5L8 11L14 5'/></svg>") !default;
$accordion-button-icon:         url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none"><circle cx="15" cy="15" r="15" fill="#CF0000"/><path d="M15 22.6369V7.36337V22.6369Z" fill="#CF0000"/><path d="M15 22.6369V7.36337" stroke="white" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/><path d="M22.6368 15.0001H7.36328H22.6368Z" fill="#CF0000"/><path d="M22.6368 15.0001H7.36328" stroke="white" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/></svg>');
$accordion-button-active-icon:         url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none"><circle cx="15" cy="15" r="15" fill="#CF0000"/><path d="M9.6001 20.4L20.4001 9.59998L9.6001 20.4Z" fill="#CF0000"/><path d="M9.6001 20.4L20.4001 9.59998" stroke="white" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/><path d="M20.4001 20.4L9.6001 9.59998L20.4001 20.4Z" fill="#CF0000"/><path d="M20.4001 20.4L9.6001 9.59998" stroke="white" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/></svg>');


//form
$form-label-color: $primary;
$input-bg: $light-gray;
$input-border-width: 0;
$input-padding-y: 1rem;
