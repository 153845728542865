.navbar {
        background-color: rgba($primary, .2);
        transition: all .3s ease-in-out;
        &.scrolled{
            --bs-bg-opacity: 1;
            background-color: $primary;
        }
        &.show{
          --bs-bg-opacity: 1;
          background-color: $primary;
        }
        @media (max-width: 991px) {
            .navbar-collapse{
              transition: all .3s ease-in-out;
                &.show{
                  height: 100vh;
                }
                .nav-item{
                    text-align: center;
                }
                .nav-link{
                    font-size: 2rem;
            
                    &.active,
                    &.show,
                    &:hover {
                      border-width: 0;
                    }
                }
            }
        }
}



.navbar-nav {
   
    .nav-link {
      font-family: $headings-font-family;
      &.active,
      &.show{
        border-bottom: 3px solid $secondary;
      }
      // &:hover {
      //   border-bottom: 3px solid $secondary;
      // }
    }
  
  }

// 
.navbar-section {
  a{
    color:$white;
    border-top:4px solid $primary;
    transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out;
    &:hover{
      border-top:4px solid $secondary;
      // color:rgba($white, .55);
    }
    &.active-link{
      border-top:4px solid $secondary;
      color:rgba($white, .55);
    }
  }

}