//buttons
.btn.btn-icon-left {
    padding: 10px 20px;
    display: inline-grid;
    grid-template-columns: 25px 1fr;
    grid-column-gap: 8px;
    align-items: center;
}

.btn.btn-icon-right {
    padding: 10px 20px;
    display: inline-grid;
    grid-template-columns: 1fr 25px;
    grid-column-gap: 14px;
    align-items: center;
}


.btn{
    font-family: $btn-font-family;
    &.btn-secondary{
        &:hover{
            background-color: $danger;
        }
    }
    &.btn-outline-secondary {
        color:$primary;
        &:hover {
            color: $white;
            background-color: $secondary;
            border-color: $secondary;
        }
    }
    &.btn-hover-outline{
        &:hover {
            color: var(--bs-btn-color);
            background-color: transparent;
            border-color: var(--bs-btn-bg);
        }
    }
}



