.social-icon{
    transition: color .15s ease-in-out;
    svg{
        fill:$primary;
    }
    &:hover{
        svg{
            fill:$secondary;
        }
    }
}



.video-bg{
    background-size: cover;
    overflow: hidden;
    height: 100vh;
    @media (max-width: 390px) {
        max-height: 670px;
      }
    @media (max-width: 576px) {
        max-height: 533px;
    }
    @media (min-width: 1512px) and(max-width: 1919px) {
        max-height: 875px;
    }
    @media (min-width: 1920px) {
        max-height: 863px;
    }
    video {
        object-fit: cover;
        object-position: 50% 50%;
        height: 100%;
        width: 100%;
        z-index: -2;
        @media (max-width: 769px) {
            object-position: 50% 50%;
          }
          
      }
}

.bg-overlay{
    position: relative;
    background-size: cover;
    &:before{
        content: "";
        background-color: rgba($primary,.7);
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        bottom: 0;
    }
}

.bg-overlay-sm{
    position: relative;
    @media (max-width: 769px) {
        &:before{
            content:'';
            background-color:rgba($primary,.6);
            position: absolute;
            width: 100%;
            height: 100%;
            top:0;
            left: 0;
        }

    }
  }


.rotate-90-left-right-bottom{
    transform: rotate(-90deg);
    transform-origin: right bottom;
}

.rotate-90{
    transform: rotate(90deg);
}


// .swiper-slide {
//     text-align: center;
//     font-size: 18px;
//     background: #fff;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//   }

//   .swiper-slide img {
//     display: block;
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
//   }

  .overlay-logo{
    bottom: -80px;
    right:50px;
  }


