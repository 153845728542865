// Include any default variable overrides here (though functions won't be available)
@import './variables';
@import './navbar';
@import './type';
@import './custom';

@import "bootstrap/scss/bootstrap";
@import './utilities';
@import './buttons';
@import './slider';
@import './accordion';
@import './form';
// @import '../../node_modules/swiper/swiper.css';
// @import 'swiper/css/navigation';
// @import 'swiper/css/pagination';
// import Swiper and modules styles
// @import '../../node_modules/swiper/swiper.scss';

// Then add additional custom code here

.pi-spin {
    -webkit-animation: pi-spin 2s linear infinite;
    animation: pi-spin 2s linear infinite;
    width:32px;
    margin-top:50px;
}


@keyframes pi-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
}

//Utilities
@media (min-width: 992px) {
    .h-lg-100{
        height: 100%;
    }
}





// .text-light-grey{
//     color:$light-grey;
// }

.text-darkest-grey{
    color: $gray-900;
}
// svg icons
// .icon-light-grey{
//     filter:invert(0.2);
// }

//Links
.link{
    text-decoration: none;
    text-transform: capitalize;
    color:$gray;
    font-weight: 700;

}



.dropdown:hover>.dropdown-menu {
    display: block;
  }
  

.top-nav{
    a,p{
        font-size: $font-size-sm;
    }
}



// .sponsers-header-slider{
//     .slider-img-container{
//         height: 9rem;
//         background-size: cover;
//         background-position: center;
//         background-repeat: no-repeat;
//     }

//     .slider-img-container-mob{
//         height: 4rem;
//         background-size: cover;
//         background-position: top;
//         background-repeat: no-repeat;
//     }
// }




//card
.card-img-background{
    background-position: center;
    background-size: cover;
    border-bottom-left-radius: var(--bs-border-radius);
    border-top-left-radius: var(--bs-border-radius);
}

.card-lg-xl{
    .card-img-background{
        height: 311px;
        border-bottom-left-radius: 0;
    }
    @media (max-width: 992px) {
        .card-title{
            font-size: 1.25rem;
        }
        .card-body{
            font-size: $font-size-base;
        }
    }

}


#latest-news-section{
    .card-img-background{
        height: 240px;
        border-bottom-left-radius: 0;
    }
}

@media (max-width: 992px) {
    .card-img-background, .card-lg-xl .card-img-background{
        height: 150px;
        border-bottom-left-radius: 0;
        border-top-left-radius: var(--bs-border-radius);
        border-top-right-radius: var(--bs-border-radius);
    }
}

@media (max-width: 425px) {
    .card-img-background, .card-lg-xl .card-img-background{
        height: 185px;
    }
}

// @media (max-width: 992px) {
//     .card-img-background{
//         height: 215px;
//     }
// }




//nav pills
.nav-pills{
    .nav-link{
        font-size: 1.75rem;
        &.active{
            border-bottom-color:$secondary;
        }
        @media (max-width: 768px) {
            font-size: 1rem;
        }
    }

}

// Form
.g-recaptcha {
    transform: scale(0.77);
    -webkit-transform: scale(0.77);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
}

//Details page
.wysiwyg-content{
    p,h1,h2,h3,h4,h5,h6{
        margin-bottom: 2rem;
    }

    img{
        border-radius: var(--bs-border-radius);
    }
}
