// //fonts
// .h1-xl{
//     @include font-size(3.75rem);
//     font-weight: $font-weight-bolder;
//     line-height: 4.5rem;
//     @media (max-width: 425px) {
//         line-height: normal;
//     }
// }
// .h6-banner{
//     color:$secondary;
//     letter-spacing: 0.125rem;
//     text-transform: uppercase;
// }

.h7{
    font-size: $h7-font-size;
    font-weight: 500;
}

.accent-font{
    font-family: 'Exo 2', sans-serif;
}

.h1-sm{
    @media (max-width: 425px) {
        font-size: $h2-font-size;
    }
}
