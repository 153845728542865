// @import "bootstrap/scss/functions";
// @import "bootstrap/scss/variables";
// @import "bootstrap/scss/utilities";

// $utilities: map-merge($utilities: (
//         "rotate": (
//             property: rotate,
//             values: (0: 0deg,
//                 90: 90deg,
//                 180: 180deg,
//                 n90:-90deg,
//                 n180:-180deg,
//             )))
// );

$utilities: map-merge(
    
  $utilities,
  (
    "rotate": (
        property: transform,
        values: (
          0: rotate(0deg),
          90: rotate(90deg),
          180: rotate(180deg),
        )
    ),
    "n-rotate": (
        property: transform,
        class: rotate-negative,
        values: (
          0: rotate(-0deg),
          90: rotate(-90deg),
          180: rotate(-180deg),
        )
    ),
  )
);

.b-n10{
  bottom: -10%;
}

.bg-cover-right{
  background-size: cover;
  background-position: right bottom;
}