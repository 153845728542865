$themeColor: $secondary;
.swiper-slides-per-view {
    .swiper-slide {
        .img-container {
            background-size: cover;
            background-position: center;
            height: 390px;
            width: auto;
            // @media(min-width: 640px) and (max-width: 992px){
            //     width: 316px;
            // }
            // min-height: 200px;
            position: relative;
        }

        .slider-container {
            &:hover {
                .img-container {
                    &::before {
                        content: "+";
                        text-align: center;
                        color: $white;
                        padding-top: 50%;
                        font-size: 2rem;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: rgba($secondary, .8);
                    }
                }
            }
        }

    }
}

.singleImgSlider {
    .swiper-button-next,
    .swiper-button-prev{
        background-color: $primary;
        height: 39px;
        width: 39px;
        &:after{
            font-size: 20px;
            color:$white;
        }
    }
    .swiper-button-next{
        right: 0;
    }
    .swiper-button-prev{
        left: 0;
    }
}

.swiper-pagination-bullet-active {
    background-color: $primary;
}


